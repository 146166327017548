export default {
  state: {
    alert1: {
      color: "",
      msg: "",
      show: false,
      delay: 8000,
    },
  },
  getters: {
    alert1: (state) => state.alert1,
  },
  mutations: {
    showAlert1(state, [color, msg, delay = 8000]) {
      state.alert1.color = color;
      state.alert1.msg = msg;
      state.alert1.show = true;
      state.alert1.delay = delay;

      setTimeout(() => {
        state.alert1.show = false;
      }, delay);
    },
  },
  actions: {
    showAlert1(context, [color, msg, delay = 8000]) {
      context.commit("showAlert1", [color, msg, delay]);
    },
  },
};
