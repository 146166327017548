export const generatePreview = (preview) => {
  const builderElements = preview.querySelectorAll("[data-builder-edit]");
  const fileFrame = preview.querySelector("#fileFrame");
  const pickers = preview.querySelectorAll(".mdb-picker");
  builderElements.forEach((element) => {
    element.removeAttribute("data-builder-edit");
    element.removeAttribute("data-builder-name");
    element.removeAttribute("data-builder-href");
    element.removeAttribute("contenteditable");
  });
  pickers.forEach((picker) => picker.remove());
  fileFrame.remove();

  return preview;
};
