import axios from "axios";

export default {
  state: {
    logged: false,
    loginModal: false,
    user: null,
    authorization: false,
  },
  getters: {
    logged: (state) => state.logged,
    loginModal: (state) => state.loginModal,
    user: (state) => state.user,
    authorization: (state) => state.authorization,
  },
  mutations: {
    login(state, user) {
      state.logged = true;
      state.loginModal = false;
      state.user = { ...user };
    },
    logout(state) {
      state.logged = false;
      state.loginModal = false;
      state.user = null;
      localStorage.clear();
    },
    resetUser(state) {
      state.logged = false;
      state.user = null;
    },
    toggleLoginModal(state) {
      state.loginModal = !state.loginModal;
    },
    startAuthorization(state) {
      state.authorization = true;
    },
    stopAuthorization(state) {
      state.authorization = false;
    },
  },
  actions: {
    async login(context, payload) {
      context.commit("startLoading");

      axios
        .post(
          process.env.VUE_APP_LOGIN,
          {
            username: payload.username,
            password: payload.password,
          },
          { withCredentials: true },
        )
        .then((res) => {
          const user = {
            username: res.data.username,
            id: res.data.id,
            avatar: res.data.avatar,
          };

          context.commit("login", user);
          context.dispatch("loadUserProjects");
          context.dispatch("showAlert1", ["success", "Successfully logged in"]);
        })
        .catch(() => {
          context.dispatch("showAlert1", [
            "danger",
            "Wrong username or password",
          ]);
        })
        .then(() => {
          context.commit("stopLoading");
        });
    },
    async register(context, payload) {
      context.commit("startLoading");

      if (payload.password !== payload.repeatPassword) {
        context.commit("stopLoading");
        context.dispatch("showAlert1", ["danger", "Passwords are not equal"]);

        return;
      }

      axios
        .post(
          process.env.VUE_APP_REGISTER,
          {
            name: payload.name,
            username: payload.username,
            email: payload.email,
            password: payload.password,
            repeatPassword: payload.repeatPassword,
            newsletter: payload.newsletter,
          },
          { withCredentials: true },
        )
        .then((res) => {
          if (res.data.message) {
            context.dispatch("showAlert1", ["danger", res.data.message]);
          } else {
            const user = {
              username: res.data.user_nicename,
              id: res.data.id,
              avatar: res.data.avatar.split("src='")[1].split("'")[0],
            };

            context.commit("login", user);
            context.dispatch("showAlert1", [
              "success",
              "Successfully registered and logged in",
            ]);
          }
        })
        .catch(() => {
          context.dispatch("showAlert1", ["danger", "Error occured"]);
        })
        .then(() => {
          context.commit("stopLoading");
        });
    },
    async logout(context) {
      context.commit("startLoading");
      context.commit("clearProject");
      context.commit("setUserProjects", []);

      axios
        .post(process.env.VUE_APP_LOGOUT, null, { withCredentials: true })
        .then(() => {
          context.commit("logout");
        })
        .catch((error) => {
          console.error(error);
        })
        .then(() => {
          context.commit("stopLoading");
        });
    },
    async auth(context) {
      context.commit("startAuthorization");

      axios
        .get(process.env.VUE_APP_AUTH, { withCredentials: true })
        .then((res) => {
          if (res.status === 200) {
            const avatar = res.data.avatar.startsWith("<img")
              ? res.data.avatar.split("src='")[1].split("'")[0]
              : res.data.avatar;

            const user = {
              username: res.data.username || "",
              id: res.data.id,
              avatar,
            };
            context.commit("login", user);
            context.dispatch("loadUserProjects");
          } else {
            context.commit("resetUser");
            context.commit("clearLocalStorage");
            context.commit("clearProject");
            context.commit("setUserProjects", []);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(function () {
          context.commit("stopAuthorization");
        });
    },
    async mdbAuth(context) {
      context.commit("startAuthorization");

      axios
        .get(process.env.VUE_APP_MDB_AUTH, { withCredentials: true })
        .then((res) => {
          if (res.status === 200) {
            const user = {
              username: res.data.username,
              id: res.data.id,
              avatar: res.data.avatar,
            };

            context.commit("login", user);
            context.dispatch("loadUserProjects");
          } else {
            context.commit("resetUser");
            context.commit("clearLocalStorage");
            context.commit("clearProject");
            context.commit("setUserProjects", []);
          }
        })
        .catch(() => {})
        .then(function () {
          context.commit("stopAuthorization");
        });
    },
    toggleLoginModal(context) {
      context.commit("toggleLoginModal");
    },
  },
};
