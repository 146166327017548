import { reloadScript } from "../../utils/reloadScript";
import { nextTick } from "vue";
import axios from "axios";

export default {
  state: {
    mdbVersionId: 1,
    mdbVersion: localStorage.getItem("mdbVersion") || "",
    mdbVersions: [],
  },
  getters: {
    mdbVersions: (state) => state.mdbVersions,
    mdbVersion: (state) => state.mdbVersion,
    mdbVersionId: (state) => state.mdbVersionId,
  },
  mutations: {
    getAllMDBVersions(state, versions) {
      state.mdbVersions = versions;
    },
    setMDBVersion(state, [id, version]) {
      state.mdbVersionId = id;
      state.mdbVersion = version;
      localStorage.setItem("mdbVersion", version);
      // const tempUserComponents = [...state.userComponents];
      // state.userComponents = [];
      nextTick(() => {
        // state.userComponents = tempUserComponents;
        reloadScript(version);
      });
    },
    resetVersion(state) {
      if (state.mdbVersions.length > 0) {
        state.mdbVersionId = state.mdbVersions[0].id;
        state.mdbVersion = state.mdbVersions[0].version;
      }
    },
  },
  actions: {
    async getAllMDBVersions(context) {
      axios.get(process.env.VUE_APP_VERSIONS).then((res) => {
        let versions = [];

        res.data.forEach((version) => {
          version.selected = version.version === context.getters.mdbVersion;
          versions.push(version);
        });
        context.commit("getAllMDBVersions", versions);
      });
    },
    setMDBVersion(context, [id, version]) {
      context.commit("setMDBVersion", [id, version]);
      context.dispatch("getComponents");
    },
  },
};
