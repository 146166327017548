<template>
  <teleport to="body" v-if="appendToBody">
    <component
      :id="uid"
      ref="alertRef"
      :is="tag"
      :class="className"
      :style="[displayStyle, widthStyle, alignmentStyle]"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      :data-mdb-stacking="stacking ? true : null"
      :data-mdb-static="props.static ? true : null"
      v-bind="attrs"
    >
      <slot />
      <button
        v-if="dismiss"
        type="button"
        class="mdb-btn-close"
        aria-label="Close"
        @click="hide"
      ></button>
    </component>
  </teleport>
  <component
    v-else
    :id="uid"
    ref="alertRef"
    :is="tag"
    :class="className"
    :style="[displayStyle, widthStyle, alignmentStyle]"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    :data-mdb-stacking="stacking ? true : null"
    :data-mdb-static="props.static ? true : null"
    v-bind="attrs"
  >
    <slot />
    <button
      v-if="dismiss"
      type="button"
      class="mdb-btn-close"
      aria-label="Close"
      @click="hide"
    ></button>
  </component>
</template>

<script>
import {
  computed,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  watch,
  watchEffect,
} from "vue";
import { on, off } from "@/utils/MDBEventHandlers";
import { getUID } from "@/utils/getUID";
import MDBStackableElements from "@/utils/MDBStackableElements";

export default {
  name: "MDBAlert",
  props: {
    tag: {
      type: String,
      default: "div",
    },
    modelValue: Boolean,
    offset: {
      type: String,
      default: "10",
    },
    position: {
      type: String,
      default: "top-right",
    },
    width: {
      type: [String, null],
      default: null,
    },
    color: {
      type: String,
    },
    container: String,
    autohide: {
      type: Boolean,
      default: true,
    },
    animation: {
      type: Boolean,
      default: true,
    },
    delay: {
      type: [Number, String],
      default: 5000,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    stacking: {
      type: Boolean,
      default: true,
    },
    static: {
      type: Boolean,
      default: false,
    },
    dismiss: Boolean,
    id: String,
    hidden: Boolean,
  },
  emits: ["update:modelValue", "show", "shown", "hide", "hidden"],
  setup(props, { attrs, emit }) {
    // -------------- Classes and Styles --------------
    const className = computed(() => {
      return [
        "mdb-alert",
        props.animation && "mdb-fade",
        "mdb-text-start",
        props.color && `mdb-alert-${props.color}`,
        toastPositionClasses.value,
        showClass.value && "mdb-show",
        props.dismiss && "mdb-alert-dismissible",
      ];
    });
    const toastPositionClasses = computed(() => {
      if (props.static) return;
      return props.container ? "mdb-alert-absolute" : "mdb-alert-fixed";
    });

    const widthStyle = computed(() => `width: ${props.width}`);
    const alignmentStyle = ref(null);
    const displayStyle = ref(null);
    const showClass = ref(props.static && !props.hidden ? true : false);

    const uid = props.id || getUID("MDBAlert-");

    // -------------- Refs --------------
    const alertRef = ref(null);

    // -------------- Positioning --------------
    const verticalOffset = () => {
      if (!props.stacking || !props.position) return 0;

      return calculateStackingOffset();
    };

    const getPosition = () => {
      if (!props.position) return null;
      const [y, x] = props.position.split("-");
      return { y, x };
    };

    const updatePosition = () => {
      const { y } = getPosition();
      const offsetY = verticalOffset();

      //  quick update vertical position for stack placement
      alertRef.value.style[y] = `${
        parseInt(offsetY) + parseInt(props.offset)
      }px`;
      // update alignmentStyle value
      // without that alertRef.value.style[y] will be overwritten on hide by alignementStyle
      setupAlignment();
    };

    const setupAlignment = () => {
      const offsetY = verticalOffset();
      const position = getPosition();

      const oppositeY = position.y === "top" ? "bottom" : "top";
      const oppositeX = position.x === "left" ? "right" : "left";
      if (position.x === "center") {
        alignmentStyle.value = {
          [position.y]: `${parseInt(offsetY) + parseInt(props.offset)}px`,
          [oppositeY]: "unset",
          left: "50%",
          transform: "translate(-50%)",
        };
      } else {
        alignmentStyle.value = {
          [position.y]: `${parseInt(offsetY) + parseInt(props.offset)}px`,
          [position.x]: `${props.offset}px`,
          [oppositeY]: "unset",
          [oppositeX]: "unset",
          transform: "unset",
        };
      }
    };

    watch(
      () => props.position,
      () => setupAlignment(),
    );

    watch(
      () => props.offset,
      () => setupAlignment(),
    );

    // -------------- Stacking --------------
    const {
      setStack,
      calculateStackingOffset,
      nextStackElements,
      resetStackingOffset,
    } = MDBStackableElements();
    const observer = ref(null);

    const setupStacking = () => {
      setStack(alertRef, alertRef.value, ".mdb-alert", {
        position: getPosition().y,
        offset: props.offset,
        container: props.container,
        filter: (el) => {
          return el.dataset.mdbStacking && !el.dataset.mdbStatic;
        },
      });

      observerStacking();
    };

    // MutationObserver is a workaround for Vue not being able to communicate
    const observerStacking = () => {
      observer.value = new MutationObserver((mutations) => {
        for (const m of mutations) {
          const newValue = m.target.getAttribute(m.attributeName);
          nextTick(() => {
            onShouldStackUpdate(newValue, m.oldValue);
          });
        }
      });

      observer.value.observe(alertRef.value, {
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ["class"],
      });
    };

    const updateAlertStack = () => {
      const nextElements = nextStackElements();
      if (!nextElements.length) {
        return;
      }
      nextElements.forEach((el) => {
        if (el.id !== uid.value) {
          el.classList.add("mdb-should-stack-update");
        }
      });
    };

    // MutationObserver
    // will fire on component class change
    const onShouldStackUpdate = (classAttrValue) => {
      const classList = classAttrValue.split(" ");
      if (classList.includes("mdb-should-stack-update")) {
        updatePosition();
        alertRef.value.classList.remove("mdb-should-stack-update");
      }
    };

    // -------------- Open/Close --------------
    const isActive = ref(props.modelValue);
    const timeoutValue = ref(null);

    watchEffect(() => {
      isActive.value = props.modelValue;
    });

    const openAlert = () => {
      emit("show");
      _clearTimeout();
      setupAlignment();

      displayStyle.value = "display: block";

      const complete = () => {
        emit("shown");
        off(alertRef.value, "transitionend", complete);

        if (props.autohide) {
          timeoutValue.value = setTimeout(hide, props.delay);
        }
      };

      nextTick(() => {
        setTimeout(() => {
          showClass.value = true;
        }, 0);

        if (props.animation) {
          on(alertRef.value, "transitionend", complete);
        } else {
          complete();
        }
      });
    };

    const closeAlert = () => {
      emit("hide");

      const complete = () => {
        displayStyle.value = "display: none";
        alignmentStyle.value = null;

        emit("hidden");
        off(alertRef.value, "transitionend", complete);

        if (props.stacking && !props.static) {
          updateAlertStack();
        }
      };

      showClass.value = false;

      if (props.stacking && !props.static) {
        resetStackingOffset();
      }

      if (props.animation) {
        on(alertRef.value, "transitionend", complete);
      } else {
        complete();
      }
    };

    watch(
      () => isActive.value,
      (isActive) => {
        if (isActive) {
          openAlert();
        } else {
          closeAlert();
        }
      },
    );

    const show = () => {
      emit("update:modelValue", true);
    };

    const hide = () => {
      if (props.autohide && !timeoutValue.value) return;
      emit("update:modelValue", false);
    };

    const _clearTimeout = () => {
      clearTimeout(timeoutValue.value);
      timeoutValue.value = null;
    };

    // -------------- Lifecycle Hooks --------------
    onMounted(() => {
      if (
        (props.static && props.hidden) ||
        (!props.modelValue && !props.static)
      ) {
        displayStyle.value = "display: none";
      }
      if (props.container) {
        const containerEl = document.querySelector(props.container);
        if (!containerEl) return;

        containerEl.classList.add("mdb-parent-alert-relative");
      }

      if (props.stacking && !props.static) {
        setupStacking();
      }
    });

    onUnmounted(() => {
      _clearTimeout();
      observer.value.disconnect();
    });

    return {
      uid,
      className,
      widthStyle,
      alignmentStyle,
      displayStyle,
      alertRef,
      isActive,
      show,
      hide,
      attrs,
      props,
    };
  },
};
</script>
