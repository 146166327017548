<template>
  <div v-if="isVertical" :class="columnClassName">
    <component :is="tag" :class="className">
      <slot />
    </component>
  </div>
  <component v-else :is="tag" :class="className">
    <slot />
  </component>
</template>

<script>
import { computed, inject } from "vue";

export default {
  name: "MDBTabNav",
  props: {
    tag: {
      type: String,
      default: "ul",
    },
    pills: {
      type: Boolean,
    },
    justify: {
      type: Boolean,
    },
    fill: {
      type: Boolean,
    },
    col: {
      type: String,
      default: "3",
    },
    tabsClasses: String,
  },
  setup(props, { attrs }) {
    const className = computed(() => {
      return [
        "mdb-nav",
        props.pills ? "mdb-nav-pills" : "mdb-nav-tabs",
        props.justify && "mdb-nav-justified",
        props.fill && "mdb-nav-fill",
        isVertical.value && "mdb-flex-column",
        props.tabsClasses && props.tabsClasses,
      ];
    });

    const columnClassName = computed(() => {
      return [`mdb-col-${props.col}`];
    });

    const isVertical = inject("isVertical", false);

    return {
      className,
      columnClassName,
      isVertical,
      props,
      attrs,
    };
  },
};
</script>
