import axios from "axios";

export default {
  state: {
    templates: {},
  },
  getters: {
    templates: (state) => state.templates,
  },
  mutations: {
    setTemplates(state, templates) {
      state.templates = templates;
    },
  },
  actions: {
    async loadTemplates(context) {
      axios.get(process.env.VUE_APP_TEMPLATES).then((res) => {
        if (res.status === 200) {
          context.commit("setTemplates", res.data);
        }
      });
    },
    async loadTemplate(context, id) {
      axios
        .get(`${process.env.VUE_APP_TEMPLATE_COMPONENTS}/${id}`)
        .then((res) => {
          context.commit("loadUserComponents", res.data);
        });
    },
  },
};
