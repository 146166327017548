<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>

  <MDBLoading
    v-model="loading"
    loadingText=""
    spinnerClasses="mdb-text-black"
  />

  <MDBAlert
    v-model="alert1.show"
    id="alert1"
    :color="alert1.color"
    position="top-right"
    width="535px"
    dismiss
    appendToBody
    autohide
    :delay="alert1.delay"
    >{{ alert1.msg }}
  </MDBAlert>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import MDBLoading from "@/components/mdb/MDBLoading";
import MDBAlert from "@/components/mdb/MDBAlert";

export default {
  components: {
    MDBLoading,
    MDBAlert,
  },
  setup() {
    const store = useStore();
    const storeLoading = computed(() => store.getters.loading);
    const storeAlert1 = computed(() => store.getters.alert1);
    const loading = ref(false);

    const alert1 = ref({
      color: "",
      msg: "",
      show: false,
      delay: 5000,
    });

    watch(
      () => storeAlert1.value,
      (alert1Data) => {
        alert1.value.color = alert1Data.color;
        alert1.value.msg = alert1Data.msg;
        alert1.value.show = alert1Data.show;
        alert1.value.delay = alert1Data.delay;
      },
      { deep: true },
    );

    watch(
      () => storeLoading.value,
      (value) => (loading.value = value),
      { deep: true },
    );

    store.dispatch("mdbAuth");

    return {
      loading,
      alert1,
    };
  },
};
</script>

<style lang="scss">
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  height: 100%;
}
.mdb-fade-enter-active,
.mdb-fade-leave-active {
  transition: opacity 0.3s ease;
  z-index: 10000;
}

.mdb-fade-enter-from,
.mdb-fade-leave-to {
  opacity: 0;
  z-index: 10000;
}
</style>
