<template>
  <header class="mdb-mb-6">
    <!-- Navbar -->
    <nav class="mdb-navbar mdb-navbar-expand-lg mdb-navbar-light mdb-bg-white">
      <!-- Container wrapper -->
      <div class="mdb-container">
        <div class="mdb-d-flex mdb-align-items-center">
          <!-- Navbar brand -->
          <a class="mdb-navbar-brand mdb-me-2" href="https://mdbootstrap.com/">
            <img
              src="https://mdbootstrap.com/img/logo/mdb-transaprent-noshadows.png"
              height="16"
              alt=""
              loading="lazy"
              style="margin-top: -1px"
            />
          </a>
          <MDBBtn
            tag="a"
            color="link"
            href="https://youtu.be/eN5eOIP8_X4"
            target="_blank"
            rel="nofollow"
            class="
              mdb-px-3 mdb-me-2 mdb-text-capitalize mdb-text-reset mdb-text-dark
            "
          >
            Learn how to use it
          </MDBBtn>
        </div>

        <div class="mdb-d-flex mdb-align-items-center">
          <LoginPanel />
        </div>
      </div>
      <!-- Container wrapper -->
    </nav>
    <!-- Navbar -->
  </header>
</template>

<script>
import MDBBtn from "@/components/mdb/MDBBtn";
import LoginPanel from "@/components/LoginPanel";

export default {
  name: "HomeNavbar",
  components: {
    MDBBtn,
    LoginPanel,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
