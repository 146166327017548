import { nextTick } from "vue";
import semver from "semver";
import axios from "axios";

const recalculateIndexes = (components) => {
  return components.map((component, index) => ({
    ...component,
    order: index,
  }));
};

const getCompatibilities = async () => {
  return axios.get(process.env.VUE_APP_COMPATIBILITIES).then((res) => res.data);
};

export default {
  state: {
    templateComponents: [],
    userComponents: JSON.parse(localStorage.getItem("userComponents")) || [],
    templateCategories: [],
    draggedComponent: null,
    dropIndex: -1,
  },
  getters: {
    templateComponents: (state) => state.templateComponents,
    templateCategories: (state) => state.templateCategories,
    userComponents: (state) => state.userComponents,
    draggedComponent: (state) => state.draggedComponent,
    dropIndex: (state) => state.dropIndex,
  },
  mutations: {
    getComponents(state, templateComponents) {
      state.templateComponents = templateComponents;
    },
    getCategories(state, templateCategories) {
      const categories = [
        {
          id: "tab-all",
          name: "All",
        },
      ];

      templateCategories.forEach((cat) => {
        categories.push({
          id: `tab-${cat}`,
          name: cat,
        });
      });

      state.templateCategories = categories;
    },
    addUserComponent(state, dbComponentId) {
      const newUserComponent = {
        ...state.templateComponents.find(
          (component) => component.id === dbComponentId,
        ),
        timestamp: Date.now(),
      };

      nextTick(() => {
        if (state.draggedComponent !== null) {
          const dropItemIndex = state.userComponents.findIndex(
            (component) => component.order === state.dropIndex,
          );

          if (dropItemIndex === state.userComponents.length) {
            state.userComponents.push(newUserComponent);
          } else if (state.dropIndex === 0) {
            state.userComponents.unshift(newUserComponent);
          } else if (state.dropIndex !== -1) {
            const tempUserComponents = [...state.userComponents];
            state.userComponents = [];
            const listStart = tempUserComponents.slice(0, state.dropIndex);
            const listEnd = tempUserComponents.slice(state.dropIndex);
            state.userComponents.push(
              ...listStart,
              newUserComponent,
              ...listEnd,
            );
          }
        } else {
          state.userComponents.push(newUserComponent);
        }

        state.userComponents = recalculateIndexes(state.userComponents);

        localStorage.setItem(
          "userComponents",
          JSON.stringify(state.userComponents),
        );

        state.draggedComponent = null;
        state.dropIndex = -1;
      });
    },
    updateUserComponentConfig(state, [id, config]) {
      const editedComponent = state.userComponents.find(
        (component) => component.order === id,
      );
      editedComponent.config = config;

      localStorage.setItem(
        "userComponents",
        JSON.stringify(state.userComponents),
      );
    },
    changeComponentSize(state, [id, size]) {
      const editedComponent = state.userComponents.find(
        (component) => component.order === id,
      );
      editedComponent.config.container = size;

      localStorage.setItem(
        "userComponents",
        JSON.stringify(state.userComponents),
      );
    },
    setDragged(state, component) {
      state.draggedComponent = component;
    },
    setDropIndex(state, index) {
      if (state.draggedComponent) {
        state.dropIndex = index;
      }
    },
    sortComponentsIndexes(state, components) {
      state.userComponents = [];

      state.userComponents = recalculateIndexes(components);

      localStorage.setItem(
        "userComponents",
        JSON.stringify(state.userComponents),
      );
    },
    moveComponent(state, { order, direction }) {
      const tempComponent = {
        ...state.userComponents[order + direction],
      };
      const tempUserComponents = [...state.userComponents];
      tempUserComponents[order + direction] = {
        ...tempUserComponents[order],
      };
      tempUserComponents[order] = tempComponent;

      state.userComponents = recalculateIndexes(tempUserComponents);

      localStorage.setItem(
        "userComponents",
        JSON.stringify(state.userComponents),
      );
    },
    deleteComponent(state, order) {
      state.userComponents = recalculateIndexes(
        state.userComponents.filter((component) => component.order !== order),
      );

      localStorage.setItem(
        "userComponents",
        JSON.stringify(state.userComponents),
      );
    },
    saveUserComponents(state, projectId) {
      state.userComponents.forEach((component) => {
        axios.post(
          process.env.VUE_APP_USER_COMPONENTS,
          {
            config: JSON.stringify(component.config),
            order: component.order + 1,
            project_id: projectId,
            component_id: component.id,
          },
          { withCredentials: true },
        );
      });
    },
    loadUserComponents(state, components) {
      const loadedComponents = [];
      components.forEach((component, i) => {
        loadedComponents.push({
          id: component.component_id,
          name: component.componentData.name,
          category: component.componentData.category,
          img: component.componentData.img,
          html: component.componentData.html,
          config: JSON.parse(component.config),
          order: component.order - 1,
          timestamp: Date.now() + i,
        });
      });

      nextTick(() => {
        state.userComponents = recalculateIndexes(loadedComponents);
        localStorage.setItem(
          "userComponents",
          JSON.stringify(state.userComponents),
        );
      });
    },
    clearUserComponents(state) {
      state.userComponents = [];
      localStorage.removeItem("userComponents");
    },
  },
  actions: {
    async getComponents(context) {
      if (!context.getters.mdbVersion) {
        return;
      }

      let compatibilityLevel = 1;
      const compatibilites = await getCompatibilities();

      compatibilites.forEach((compatibility) => {
        if (
          compatibility.compatibility !== "latest" &&
          semver.lte(context.getters.mdbVersion, compatibility.compatibility)
        ) {
          compatibilityLevel = compatibility.id;
        }
      });

      axios.get(process.env.VUE_APP_COMPONENTS).then((res) => {
        const components = [];

        res.data.forEach((component) => {
          if (component.compatibility !== compatibilityLevel) {
            return;
          }

          component.config = JSON.parse(component.config);
          components.push(component);
        });

        const pickedComponentNamesArr = components.map(
          (component) => component.name,
        );

        res.data.forEach((component) => {
          if (!pickedComponentNamesArr.includes(component.name)) {
            component.config = JSON.parse(component.config);
            components.push(component);
          }
        });

        const categories = [
          ...new Set(components.map((component) => component.category)),
        ];

        context.commit("getCategories", categories);
        context.commit("getComponents", components);
      });
    },
    async addUserComponent(context, id) {
      // hot-fix for templateComponent config being overwritten by userComponent config
      await context.dispatch("getComponents");
      context.commit("addUserComponent", id);
    },
    updateUserComponentConfig(context, [id, config]) {
      context.commit("updateUserComponentConfig", [id, config]);
    },
    startDrag(context, component) {
      context.commit("setDragged", component);
    },
    endDrag(context, id) {
      context.commit("addUserComponent", id);
    },
    drop(context, index) {
      context.commit("setDropIndex", index);
    },
    sortComponentsIndexes(context, components) {
      context.commit("sortComponentsIndexes", components);
    },
    moveComponent(context, { order, direction }) {
      context.commit("moveComponent", { order, direction });
    },
    deleteComponent(context, order) {
      context.commit("deleteComponent", order);
    },
    changeComponentSize(context, [id, size]) {
      context.commit("changeComponentSize", [id, size]);
    },
  },
};
