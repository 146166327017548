export const reloadScript = (version) => {
  const oldScript = document.getElementById("mdbJS");
  if (oldScript) {
    oldScript.remove();
  }

  const newScript = document.createElement("script");
  newScript.id = "mdbJS";
  newScript.src = `${process.env.VUE_APP_MDB_UI_KIT}/MDB5-STANDARD-UI-KIT-Free-${version}/js/mdb.min.js`;
  document.head.appendChild(newScript);
};
