import download from "downloadjs";
import axios from "axios";
import { generatePreview } from "../../utils/generatePreview";

export default {
  state: {
    userProjects: [],
    projectId: localStorage.getItem("projectId") || null,
    projectName: localStorage.getItem("projectName") || "my-project",
    projectUrl: localStorage.getItem("projectUrl") || "",
    projectConfig: JSON.parse(localStorage.getItem("projectConfig")) || {
      margin: 0,
      padding: 0,
      container: "none",
      color: null,
    },
    theme: localStorage.getItem("theme") || "light.1",
  },
  getters: {
    projectId: (state) => state.projectId,
    projectName: (state) => state.projectName,
    projectUrl: (state) => state.projectUrl,
    projectConfig: (state) => state.projectConfig,
    userProjects: (state) => state.userProjects,
    theme: (state) => state.theme,
  },
  mutations: {
    clearProject(state) {
      state.projectId = null;
      state.projectName = "my-project";
      state.projectUrl = "";
      state.projectConfig = {
        margin: 0,
        padding: 0,
        container: "none",
        color: null,
      };
    },
    downloadProject(state, context) {
      const preview = generatePreview(
        document.getElementById("preview").cloneNode(true),
      );

      axios
        .post(
          process.env.VUE_APP_DOWNLOAD,
          {
            html: preview.outerHTML,
            version: context.getters.mdbVersion,
            theme: state.theme,
          },
          {
            withCredentials: true,
            responseType: "arraybuffer",
          },
        )
        .then((res) => {
          if (res.status === 204) {
            context.dispatch("showAlert1", [
              "danger",
              "You must log in to download the project",
            ]);
          } else {
            download(res.data, state.projectName, "application/zip");
          }
        });
    },
    setProjectId(state, id) {
      state.projectId = id;
      localStorage.setItem("projectId", id);
    },
    changeTheme(state, theme) {
      state.theme = theme;
      localStorage.setItem("theme", theme);
    },
    setPreviewData: (state, data) => {
      if (Array.isArray(data.property) && Array.isArray(data.value)) {
        data.property.forEach((key, i) => {
          state.projectConfig[key] = data.value[i];
        });
      } else {
        state.projectConfig[data.property] = data.value;
      }
      localStorage.setItem(
        "projectConfig",
        JSON.stringify(state.projectConfig),
      );
    },
    setUserProjects(state, projects) {
      state.userProjects = projects;
    },
    clearLocalStorage() {
      localStorage.clear();
    },
    setProjectName(state, name) {
      state.projectName = name;
      localStorage.setItem("projectName", name);
    },
    setProjectUrl(state, url) {
      state.projectUrl = url;
      localStorage.setItem("projectUrl", url);
    },
    setProjectConfig(state, config) {
      state.projectConfig = JSON.parse(config);
      localStorage.setItem("projectConfig", config);
    },
  },
  actions: {
    async downloadProject(context) {
      context.commit("startLoading");
      context.dispatch("fetchProject", context);
      context.commit("stopLoading");
    },
    fetchProject(context) {
      const preview = document.getElementById("preview").cloneNode(true);
      const builderElements = preview.querySelectorAll("[data-builder-edit]");
      const fileFrame = preview.querySelector("#fileFrame");
      const pickers = preview.querySelectorAll(".mdb-picker");
      builderElements.forEach((element) => {
        element.removeAttribute("data-builder-edit");
        element.removeAttribute("data-builder-name");
        element.removeAttribute("data-builder-href");
        element.removeAttribute("contenteditable");
      });
      pickers.forEach((picker) => picker.remove());
      fileFrame.remove();

      axios
        .post(
          process.env.VUE_APP_DOWNLOAD,
          {
            html: preview.outerHTML,
            version: context.getters.mdbVersion,
            theme: context.getters.theme,
          },
          {
            withCredentials: true,
            responseType: "arraybuffer",
          },
        )
        .then((res) => {
          if (res.status === 204) {
            context.dispatch("showAlert1", [
              "danger",
              "You must log in to download the project",
            ]);
          } else {
            download(res.data, context.getters.projectName, "application/zip");
          }
        });
    },
    changeTheme(context, theme) {
      context.commit("changeTheme", theme);
    },
    save(context) {
      context.commit("startLoading");
      if (context.getters.projectId) {
        axios
          .put(
            `${process.env.VUE_APP_PROJECTS}/${context.getters.projectId}`,
            {
              name: context.getters.projectName,
              version_id: context.getters.mdbVersionId,
              theme: context.getters.theme,
              config: JSON.stringify(context.getters.projectConfig),
            },
            { withCredentials: true },
          )
          .then((res) => {
            if (res.status === 204) {
              context.dispatch("showAlert1", [
                "danger",
                "You must log in to save the project",
              ]);
              context.commit("stopLoading");
            } else if (res.data === "conflict") {
              context.dispatch("showAlert1", [
                "danger",
                "Project name already taken.",
              ]);
              context.commit("stopLoading");
            } else if (res.data === "limit") {
              context.dispatch("showAlert1", [
                "danger",
                "You have reached the maximum number of projects allowed for your account. Please upgrade your subscription plan in order to create a new project.",
              ]);
              context.commit("stopLoading");
            } else {
              axios
                .delete(
                  `${process.env.VUE_APP_USER_COMPONENTS}/${context.getters.projectId}`,
                  { withCredentials: true },
                )
                .then(() => {
                  context.commit(
                    "saveUserComponents",
                    context.getters.projectId,
                  );
                  context.commit("showAlert1", [
                    "success",
                    "Successfully saved the project",
                  ]);
                })
                .catch((error) => {
                  console.log(error);
                })
                .then(() => {
                  context.commit("stopLoading");
                });
            }
          });
      } else {
        axios
          .post(
            process.env.VUE_APP_PROJECTS,
            {
              name: context.getters.projectName,
              version_id: context.getters.mdbVersionId,
              theme: context.getters.theme,
              config: JSON.stringify(context.getters.projectConfig),
            },
            { withCredentials: true },
          )
          .then((res) => {
            if (res.status === 204) {
              context.dispatch("showAlert1", [
                "danger",
                "You must log in to save the project",
              ]);
            } else if (res.data === "conflict") {
              context.dispatch("showAlert1", [
                "danger",
                "Project name already taken.",
              ]);
              context.commit("stopLoading");
            } else if (res.data === "limit") {
              context.dispatch("showAlert1", [
                "danger",
                "You have reached the maximum number of projects allowed for your account. Please upgrade your subscription plan in order to create a new project.",
              ]);
              context.commit("stopLoading");
            } else {
              context.commit("setProjectId", res.data.id);
              context.commit("saveUserComponents", context.getters.projectId);
              context.dispatch("loadUserProjects");
              context.commit("showAlert1", [
                "success",
                "Successfully saved the project",
              ]);
            }
            context.commit("stopLoading");
          });
      }
    },
    loadProject(context, id) {
      context.commit("startLoading");
      axios
        .get(`${process.env.VUE_APP_PROJECTS}/${id}`, { withCredentials: true })
        .then((res) => {
          context.commit("setProjectId", res.data.id);
          context.commit("setProjectName", res.data.name);
          context.commit("setProjectUrl", res.data.url);
          context.commit("changeTheme", res.data.theme);
          context.commit("setProjectConfig", res.data.config);
          context.commit("setMDBVersion", [
            res.data.version.id,
            res.data.version.version,
          ]);
          axios
            .get(process.env.VUE_APP_USER_COMPONENTS, {
              params: {
                project_id: res.data.id,
              },
              withCredentials: true,
            })
            .then((res) => {
              context.commit("loadUserComponents", res.data);
            })
            .catch((error) => {
              console.log(error);
            })
            .then(() => {
              context.commit("stopLoading");
            });
        });
    },
    loadUserProjects(context) {
      axios
        .get(process.env.VUE_APP_PROJECTS, { withCredentials: true })
        .then((res) => {
          context.commit("setUserProjects", res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setPreviewData(context, { property, value }) {
      context.commit("setPreviewData", { property, value });
    },
    setProjectName(context, name) {
      context.commit("setProjectName", name);
    },
    setProjectUrl(context, url) {
      context.commit("setProjectUrl", url);
    },
    clearProject(context) {
      context.commit("clearLocalStorage");
      context.commit("clearProject");
      context.commit("clearUserComponents");
      context.commit("resetVersion");
    },
    deleteProject(context, id) {
      context.commit("startLoading");
      const projectId = id ? id : context.getters.projectId;

      axios
        .delete(`${process.env.VUE_APP_PROJECTS}/${projectId}`, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.status === 200) {
            context.commit("showAlert1", [
              "success",
              "Successfully deleted the project",
            ]);
            context.commit("clearProject");
            context.commit("clearUserComponents");
            context.commit("changeTheme", "light.1");
            context.dispatch("loadUserProjects");
            context.commit("clearLocalStorage");
          } else {
            context.commit("showAlert1", ["danger", "Error occured"]);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          context.commit("stopLoading");
        });
    },
    editProjectName(context, newName) {
      if (context.getters.logged) {
        axios
          .put(
            `${process.env.VUE_APP_PROJECTS}/${context.getters.projectId}`,
            {
              name: newName,
            },
            { withCredentials: true },
          )
          .then((res) => {
            if (res.status !== 204) {
              context.commit("setProjectName", newName);
              context.dispatch("loadUserProjects");
              context.commit("showAlert1", [
                "success",
                "Successfully renamed the project",
              ]);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        context.commit("setProjectName", newName);
        context.commit("showAlert1", [
          "success",
          "Successfully renamed the project",
        ]);
      }
    },
  },
};
