<template>
  <div class="mdb-home">
    <HomeNavbar />

    <main>
      <div class="mdb-container">
        <!-- Section: Create new -->
        <section class="mdb-mb-6">
          <router-link
            @click="clearProject"
            to="/editor"
            class="
              mdb-bg-white
              mdb-rounded-5
              mdb-p-5
              mdb-d-flex
              mdb-justify-content-center
              mdb-align-items-center
              mdb-hover-shadow
              mdb-ripple
            "
          >
            <h6 class="mdb-mb-0 mdb-text-primary">
              <i class="fas fa-plus mdb-me-2"></i>Create new project
            </h6>
          </router-link>
        </section>
        <!-- Section: Create new -->

        <section>
          <MDBTabs v-model="activeTab">
            <!-- Tabs navs -->
            <MDBTabNav pills justify tabsClasses="mdb-mb-3">
              <MDBTabItem tabId="tab-templates" href="tab-templates"
                >Templates</MDBTabItem
              >
              <MDBTabItem tabId="tab-projects" href="tab-projects"
                >Saved projects</MDBTabItem
              >
            </MDBTabNav>
            <!-- Tabs navs -->

            <!-- Tabs content -->
            <MDBTabContent>
              <MDBTabPane tabId="tab-templates">
                <section v-if="templates.length > 0">
                  <div class="mdb-row mdb-gx-md-5">
                    <div
                      class="mdb-col-md-6 mdb-mb-5"
                      v-for="(builderTemplate, key) in templates"
                      :key="key"
                    >
                      <Template :builderTemplate="builderTemplate" />
                    </div>
                  </div>
                </section>

                <section class="mdb-text-center mdb-mt-5" v-else>
                  <MDBSpinner />
                </section>
              </MDBTabPane>
              <MDBTabPane tabId="tab-projects">
                <section v-if="userProjects.length > 0">
                  <div
                    class="mdb-mb-3"
                    v-for="(userProject, key) in userProjects"
                    :key="key"
                  >
                    <Project :project="userProject" />
                  </div>
                </section>

                <section
                  class="mdb-text-center mdb-mt-5"
                  v-else-if="authorization"
                >
                  <MDBSpinner />
                </section>

                <section v-else>
                  <p>No saved projects yet</p>
                </section>
              </MDBTabPane>
            </MDBTabContent>
            <!-- Tabs content -->
          </MDBTabs>
        </section>
      </div>
    </main>

    <router-link to="/editor"
      ><div
        v-if="userComponents.length !== 0 && projectName"
        class="
          mdb-py-2
          mdb-px-3
          mdb-rounded
          mdb-shadow
          mdb-border
          mdb-border-danger
          mdb-bg-white
        "
        style="position: fixed; bottom: 25px; right: 25px; z-index: 1040"
      >
        <p class="mdb-mb-1 mdb-text-dark mdb-small">
          You are currently editing <strong>{{ projectName }}</strong
          >.
        </p>
        <p class="mdb-mb-0 mdb-text-dark mdb-small">
          Click here to go back to the editor.
        </p>
      </div></router-link
    >
  </div>
</template>

<script>
// @ is an alias to /src
import HomeNavbar from "@/components/HomeNavbar";
import Template from "@/components/Template";
import Project from "@/components/Project";
import MDBTabs from "@/components/mdb/MDBTabs";
import MDBTabNav from "@/components/mdb/MDBTabNav";
import MDBTabItem from "@/components/mdb/MDBTabItem";
import MDBTabContent from "@/components/mdb/MDBTabContent";
import MDBTabPane from "@/components/mdb/MDBTabPane";
import MDBSpinner from "@/components/mdb/MDBSpinner";
import { useStore } from "vuex";
import { computed, ref } from "vue";

export default {
  name: "Home",
  components: {
    HomeNavbar,
    Template,
    Project,
    MDBTabs,
    MDBTabNav,
    MDBTabItem,
    MDBTabContent,
    MDBTabPane,
    MDBSpinner,
  },
  setup() {
    const store = useStore();
    const userProjects = computed(() => store.getters.userProjects);
    const projectName = computed(() => store.getters.projectName);
    const userComponents = computed(() => store.getters.userComponents);
    const templates = computed(() => store.getters.templates);
    const authorization = computed(() => store.getters.authorization);

    const activeTab = ref("tab-templates");

    const clearProject = () => {
      store.dispatch("changeTheme", "light.1");
      store.dispatch("clearProject");
    };

    store.dispatch("loadTemplates");

    return {
      userProjects,
      projectName,
      clearProject,
      activeTab,
      templates,
      authorization,
      userComponents,
    };
  },
};
</script>
