import axios from "axios";
import { generatePreview } from "../../utils/generatePreview";

export default {
  state: {
    projectUrl: localStorage.getItem("projectUrl") || "",
  },
  actions: {
    publish(context) {
      context.commit("startLoading");

      if (!context.getters.logged) {
        context.dispatch("showAlert1", [
          "danger",
          "You must log in to publish the project",
        ]);
        context.commit("stopLoading");

        return;
      }

      if (!context.getters.projectId) {
        context.dispatch("showAlert1", [
          "info",
          "Please save your project first",
        ]);
        context.commit("stopLoading");

        return;
      }

      const preview = generatePreview(
        document.getElementById("preview").cloneNode(true),
      );

      axios
        .post(
          process.env.VUE_APP_PUBLISH,
          {
            html: preview.outerHTML,
            version: context.getters.mdbVersion,
            theme: context.getters.theme,
            projectId: context.getters.projectId,
            projectName: context.getters.projectName,
          },
          {
            withCredentials: true,
          },
        )
        .then((res) => {
          if (res.data.status === 200) {
            context.dispatch("setProjectUrl", res.data.page.url);
            context.dispatch("showAlert1", [
              "success",
              `Your application is available at ${res.data.page.url}`,
              20000,
            ]);
          } else if (res.data.status === 409) {
            context.dispatch("showAlert1", [
              "danger",
              "Project name already taken. Please change project name or remove existing project from MDB GO.",
            ]);
          } else {
            context.dispatch("showAlert1", [
              "danger",
              "Error occured while publishing",
            ]);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 401) {
            context.commit("toggleLoginModal");
            context.dispatch("showAlert1", [
              "info",
              "You must log in to publish the project",
            ]);
          } else {
            context.dispatch("showAlert1", [
              "danger",
              "Error occured while publishing",
            ]);
          }
        })
        .then(() => {
          context.commit("stopLoading");
        });
    },
  },
};
