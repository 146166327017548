<template>
  <MDBBtn
    v-if="!logged && !authorization"
    color="primary"
    class="mdb-btn-theme mdb-me-3"
    @click="loginModal = true"
    >Login</MDBBtn
  >
  <MDBDropdown
    v-if="logged && !authorization"
    align="end"
    v-model="navbarDropdown"
  >
    <MDBDropdownToggle
      tag="a"
      @click="navbarDropdown = !navbarDropdown"
      class="mdb-nav-link mdb-d-flex mdb-align-items-center mdb-text-dark"
    >
      <img
        :src="user.avatar"
        class="mdb-rounded-circle"
        height="22"
        alt=""
        loading="lazy"
    /></MDBDropdownToggle>
    <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
      <MDBDropdownItem
        href="https://mdbootstrap.com/profile/"
        newTab
        classes="mdb-text-dark"
        >My profile</MDBDropdownItem
      >
      <MDBDropdownItem
        href="https://mdbootstrap.com/profile/settings/"
        newTab
        classes="mdb-text-dark"
        >Settings</MDBDropdownItem
      >
      <MDBDropdownItem href="#" @click.prevent="logout" classes="mdb-text-dark"
        >Logout</MDBDropdownItem
      >
    </MDBDropdownMenu>
  </MDBDropdown>

  <div style="height: 38px" class="mdb-me-4" v-if="authorization">
    <MDBSpinner class="mdb-mt-1" />
  </div>

  <MDBModal
    id="loginModal"
    tabindex="-1"
    labelledby="loginModalLabel"
    v-model="loginModal"
  >
    <MDBModalBody>
      <MDBTabs v-model="loginTabActive">
        <!-- Tabs navs -->
        <MDBTabNav pills justify tabsClasses="mb-3">
          <MDBTabItem tabId="tab-1" href="tab-1">Login</MDBTabItem>
          <MDBTabItem tabId="tab-2" href="tab-2" ref="registerTabButton"
            >Register</MDBTabItem
          >
        </MDBTabNav>
        <!-- Tabs navs -->
        <!-- Tabs content -->
        <MDBTabContent>
          <MDBTabPane tabId="tab-1">
            <form @submit.prevent="login" class="text-center">
              <MDBInput
                class="mdb-mt-5 mdb-mb-4"
                label="Your e-mail or username"
                v-model="loginUsername"
                required
              />
              <MDBInput
                class="mdb-mb-4"
                label="Your password"
                type="password"
                v-model="loginPassword"
                required
              />
              <div class="mdb-text-center mdb-mb-4">
                <a href="https://mdbootstrap.com/my-account/lost-password/"
                  >Forgot password?</a
                >
              </div>
              <MDBBtn type="submit" block class="mdb-mb-4" color="primary"
                >Sign in</MDBBtn
              >
              <div class="mdb-text-center">
                <p>
                  Not a member?
                  <a href="#" @click="changeTab">Register</a>
                </p>
              </div>
            </form>
          </MDBTabPane>
          <MDBTabPane tabId="tab-2">
            <form @submit.prevent="register" class="text-center" :key="formKey">
              <MDBInput
                class="mdb-mt-5 mdb-mb-4"
                label="Your name"
                v-model="registerName"
                required
                minlength="3"
              />
              <MDBInput
                class="mdb-mb-4"
                label="Your username"
                v-model="registerUsername"
                required
                minlength="3"
              />
              <MDBInput
                class="mdb-mb-4"
                label="Your email"
                type="email"
                v-model="registerEmail"
                required
              />
              <MDBInput
                class="mdb-mb-4"
                label="Your password"
                type="password"
                v-model="registerPassword"
                required
                minlength="8"
              />
              <MDBInput
                class="mdb-mb-4"
                label="Repeat password"
                type="password"
                v-model="registerRepeatPassword"
                required
                minlength="8"
              />
              <MDBCheckbox
                label="I agree to sign up for MDB account notifications and newsletter"
                wrapperClass="mdb-d-flex mdb-justify-content-center mdb-mb-4"
                v-model="newsletterCheckbox"
              />
              <div
                class="
                  mdb-form-text
                  mdb-text-center
                  mdb-small
                  mdb-text-muted
                  mdb-mb-4
                "
              >
                By signing up you agree to data processing by the administrator:
                StartupFlow s.c. located in Kijowska 7, Warsaw. The
                administrator processes data following the
                <a
                  target="_blank"
                  href="https://mdbootstrap.com/privacy-policy/"
                  >Privacy Policy</a
                >.
              </div>
              <MDBBtn type="submit" block class="mdb-mb-4" color="primary"
                >Sign up</MDBBtn
              >
            </form>
          </MDBTabPane>
        </MDBTabContent>
        <!-- Tabs content -->
      </MDBTabs>
    </MDBModalBody>
    <MDBModalFooter>
      <MDBBtn color="secondary" @click="loginModal = false">Close</MDBBtn>
    </MDBModalFooter>
  </MDBModal>
</template>

<script>
import MDBBtn from "@/components/mdb/MDBBtn";
import MDBDropdown from "@/components/mdb/MDBDropdown";
import MDBDropdownToggle from "@/components/mdb/MDBDropdownToggle";
import MDBDropdownMenu from "@/components/mdb/MDBDropdownMenu";
import MDBDropdownItem from "@/components/mdb/MDBDropdownItem";
import MDBModal from "@/components/mdb/MDBModal";
import MDBModalBody from "@/components/mdb/MDBModalBody";
import MDBModalFooter from "@/components/mdb/MDBModalFooter";
import MDBInput from "@/components/mdb/MDBInput";
import MDBSpinner from "@/components/mdb/MDBSpinner";
import MDBTabs from "@/components/mdb/MDBTabs";
import MDBTabNav from "@/components/mdb/MDBTabNav";
import MDBTabItem from "@/components/mdb/MDBTabItem";
import MDBTabContent from "@/components/mdb/MDBTabContent";
import MDBTabPane from "@/components/mdb/MDBTabPane";
import MDBCheckbox from "@/components/mdb/MDBCheckbox";
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "LoginPanel",
  components: {
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBSpinner,
    MDBTabs,
    MDBTabNav,
    MDBTabItem,
    MDBTabContent,
    MDBTabPane,
    MDBCheckbox,
  },
  setup() {
    const store = useStore();
    const navbarDropdown = ref(false);
    const loginModalState = computed(() => store.getters.loginModal);
    const loginModal = ref(false);
    const user = computed(() => store.getters.user);
    const authorization = computed(() => store.getters.authorization);
    const loginTabActive = ref("tab-1");
    const registerTabButton = ref(null);
    const formKey = ref(1);

    const changeTab = () => {
      registerTabButton.value.$el.querySelector("a").click();
    };

    watch(
      () => loginModalState,
      (loginModalState) => {
        if (loginModal.value !== loginModalState.value) {
          loginModal.value = loginModalState.value;
        }
      },
      {
        deep: true,
      },
    );

    watch(
      () => loginModal.value,
      (value) => {
        if (value !== loginModalState.value) {
          store.dispatch("toggleLoginModal");
        }
      },
    );

    watch(
      () => loginTabActive.value,
      () => formKey.value++,
    );

    const loginUsername = ref("");
    const loginPassword = ref("");
    const logged = computed(() => store.getters.logged);
    const login = () =>
      store.dispatch("login", {
        username: loginUsername.value,
        password: loginPassword.value,
      });

    const registerName = ref("");
    const registerUsername = ref("");
    const registerEmail = ref("");
    const registerPassword = ref("");
    const registerRepeatPassword = ref("");
    const newsletterCheckbox = ref(false);
    const register = () =>
      store.dispatch("register", {
        name: registerName.value,
        username: registerUsername.value,
        email: registerEmail.value,
        password: registerPassword.value,
        repeatPassword: registerRepeatPassword.value,
        newsletter: newsletterCheckbox.value,
      });

    const logout = () => {
      store.dispatch("logout");
    };

    return {
      navbarDropdown,
      loginModal,
      logged,
      login,
      loginUsername,
      loginPassword,
      registerName,
      registerUsername,
      registerEmail,
      registerPassword,
      registerRepeatPassword,
      newsletterCheckbox,
      register,
      logout,
      user,
      authorization,
      loginTabActive,
      changeTab,
      registerTabButton,
      formKey,
    };
  },
};
</script>
