<template>
  <div class="mdb-bg-white mdb-overflow-hidden mdb-rounded-5">
    <!-- Header -->
    <div
      class="
        mdb-p-4 mdb-d-flex mdb-justify-content-between mdb-align-items-center
      "
    >
      <p class="mdb-fw-normal mdb-mb-0 mdb-text-dark">
        {{ project.name }}
      </p>
      <div>
        <router-link @click="loadProject(project.id)" to="/editor">
          <MDBBtn color="link" size="lg" class="mdb-me-2"
            >Edit<MDBIcon icon="pen" class="mdb-ms-2"
          /></MDBBtn>
        </router-link>
        <MDBPopconfirm
          class="mdb-btn-link mdb-btn-lg"
          @click.stop
          @confirm="deleteProject(project.id)"
          confirmText="Delete"
          >Delete<MDBIcon icon="trash" class="mdb-ms-2"
        /></MDBPopconfirm>
      </div>
    </div>
  </div>
</template>

<script>
import MDBBtn from "@/components/mdb/MDBBtn";
import MDBIcon from "@/components/mdb/MDBIcon";
import MDBPopconfirm from "@/components/mdb/MDBPopconfirm";
import { useStore } from "vuex";

export default {
  name: "Project",
  props: {
    project: Object,
  },
  components: {
    MDBBtn,
    MDBIcon,
    MDBPopconfirm,
  },
  setup() {
    const store = useStore();

    const loadProject = (id) => {
      store.dispatch("loadProject", id);
    };

    const deleteProject = (id) => {
      store.dispatch("deleteProject", id);
    };

    return {
      loadProject,
      deleteProject,
    };
  },
};
</script>
