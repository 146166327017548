export default {
  state: {
    loading: false,
  },
  getters: {
    loading: (state) => state.loading,
  },
  mutations: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
  },
  actions: {
    startLoading(context) {
      context.commit("startLoading");
    },
    stopLoading(context) {
      context.commit("stopLoading");
    },
  },
};
