<template>
  <component :is="tag" :class="className" role="status" v-bind="attrs">
    <slot></slot>
  </component>
</template>

<script>
import { computed } from "vue";

export default {
  name: "MDBSpinner",
  props: {
    tag: {
      type: String,
      default: "div",
    },
    grow: {
      type: Boolean,
      default: false,
    },
    color: String,
    size: String,
  },
  setup(props, { attrs }) {
    const className = computed(() => {
      return [
        props.grow ? "mdb-spinner-grow" : "mdb-spinner-border",
        props.color && `mdb-text-${props.color}`,
        `${
          props.size
            ? props.grow
              ? "mdb-spinner-grow-" + props.size
              : "mdb-spinner-border-" + props.size
            : ""
        }`,
      ];
    });

    return {
      className,
      props,
      attrs,
    };
  },
};
</script>
