export default {
  state: {
    picker: null,
    element: null,
    blockConfig: null,
    updateConfig: null,
  },
  getters: {
    picker: (state) => state.picker,
    element: (state) => state.element,
    blockConfig: (state) => state.blockConfig,
    updateConfig: (state) => state.updateConfig,
  },
  mutations: {
    setPicker(state, picker) {
      state.picker = picker;
    },
    setPickerConfig(state, { element, blockConfig, updateConfig }) {
      state.element = element;
      state.blockConfig = blockConfig;
      state.updateConfig = updateConfig;
    },
  },
  actions: {
    setPicker(context, picker) {
      context.commit("setPicker", picker);
    },
    setPickerConfig(context, { element, blockConfig, updateConfig }) {
      context.commit("setPickerConfig", { element, blockConfig, updateConfig });
    },
  },
};
