<template>
  <transition name="mdb-fade">
    <div v-if="isActive && !parentSelector">
      <component
        :class="loadingClassName"
        :is="tag"
        v-bind="$attrs"
        ref="loadingRef"
      >
        <div :class="spinnerClassName" role="status"></div>
        <span :class="loadingTextClassName">{{ loadingText }}</span>
      </component>
      <div
        class="mdb-loading-backdrop"
        :style="{ opacity: backdropOpacity, backgroundColor: backdropColor }"
      ></div>
    </div>
  </transition>

  <div
    v-if="parentSelector && isActive"
    class="mdb-loading-backdrop mdb-position-absolute"
    :style="{ opacity: backdropOpacity, backgroundColor: backdropColor }"
  ></div>
</template>

<script>
import { computed, onMounted, ref, watch, watchEffect } from "vue";
export default {
  name: "MDBLoading",
  props: {
    tag: {
      type: String,
      default: "div",
    },
    fullScreen: {
      type: Boolean,
      default: true,
    },
    parentSelector: String,
    modelValue: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: "mdb-spinner-border",
    },
    loadingText: {
      type: String,
      default: "Loading...",
    },
    backdropColor: {
      type: String,
      default: "black",
    },
    backdropOpacity: {
      type: Number,
      default: 0.4,
    },
    overflow: {
      type: Boolean,
      defeault: true,
    },
    spinnerClasses: String,
    textClasses: String,
  },
  setup(props) {
    const loadingClassName = computed(() => {
      return [
        props.parentSelector
          ? "mdb-loading mdb-position-absolute"
          : "mdb-loading-full mdb-position-fixed",
        "mdb-loading-spinner",
      ];
    });

    const spinnerClassName = computed(() => {
      return [props.icon, "mdb-loading-icon", props.spinnerClasses];
    });

    const loadingTextClassName = computed(() => {
      return ["mdb-loading-text", props.textClasses];
    });

    const loadingRef = ref(null);
    const isActive = ref(props.modelValue);
    watchEffect(() => (isActive.value = props.modelValue));

    watch(
      () => isActive.value,
      (cur) => {
        if (props.fullScreen && props.overflow) {
          cur
            ? (document.body.style.overflow = "hidden")
            : (document.body.style.overflow = "");
        }
      },
      { immediate: true },
    );

    onMounted(() => {
      if (props.parentSelector) {
        const parent = document.querySelector(props.parentSelector);
        parent.classList.add("mdb-position-relative");
      }
    });

    return {
      isActive,
      loadingRef,
      loadingClassName,
      spinnerClassName,
      loadingTextClassName,
    };
  },
};
</script>
