<template>
  <div class="mdb-bg-white mdb-overflow-hidden mdb-rounded-5">
    <!-- Header -->
    <div
      class="
        mdb-p-4
        mdb-d-flex
        mdb-justify-content-between
        mdb-align-items-center
        mdb-border-bottom
      "
    >
      <p class="mdb-fw-normal mdb-mb-0 mdb-text-dark">
        {{ builderTemplate.name }}
      </p>
      <router-link @click="loadTemplate(builderTemplate.id)" to="/editor">
        <MDBBtn color="link" size="lg"
          >Use it<i class="fas fa-arrow-right mdb-ms-2"></i>
        </MDBBtn>
      </router-link>
    </div>
    <!-- Header -->

    <!-- Screen -->
    <MDBScrollbar
      suppressScrollX
      class="mdb-bg-image mdb-hover-overlay mdb-position-relative"
      height="500px"
      ref="scrollbar"
    >
      <img
        :src="`${templatesImgDir}/${builderTemplate.img}`"
        :alt="builderTemplate.name"
        class="mdb-w-100"
      />
      <div
        class="
          mdb-mask mdb-d-flex mdb-justify-content-center mdb-align-items-center
        "
      >
        <div
          class="
            mdb-d-block
            mdb-py-4
            mdb-px-5
            mdb-text-center
            mdb-text-white
            mdb-rounded
          "
          style="background-color: rgba(0, 0, 0, 0.5)"
        >
          <i class="fas fa-mouse fa-3x mdb-mb-2"></i>
          <p class="mdb-mb-0">Scroll</p>
        </div>
      </div>
    </MDBScrollbar>
  </div>
</template>

<script>
import MDBBtn from "@/components/mdb/MDBBtn";
import MDBScrollbar from "@/components/mdb/MDBScrollbar";
import { useStore } from "vuex";

export default {
  name: "Template",
  props: {
    builderTemplate: Object,
  },
  components: {
    MDBBtn,
    MDBScrollbar,
  },
  setup() {
    const store = useStore();
    const templatesImgDir = process.env.VUE_APP_TEMPLATE_IMG;

    const loadTemplate = (id) => {
      store.dispatch("changeTheme", "light.1");
      store.dispatch("clearProject");
      store.dispatch("loadTemplate", id);
    };

    return {
      templatesImgDir,
      loadTemplate,
    };
  },
};
</script>
