export default {
  state: {
    display: "desktop",
    fullscreen: false,
  },
  getters: {
    display: (state) => state.display,
    fullscreen: (state) => state.fullscreen,
  },
  mutations: {
    setDisplay(state, display) {
      state.display = display;
    },
    setFullscreen(state, value) {
      if (value !== undefined) {
        state.fullscreen = value;
        return;
      }
      state.fullscreen = !state.fullscreen;
    },
  },
  actions: {
    setDisplay(context, display) {
      context.commit("setDisplay", display);
    },
    setFullscreen(context, value) {
      context.commit("setFullscreen", value);
    },
  },
};
