import components from "./modules/components";
import auth from "./modules/auth";
import view from "./modules/view";
import pickers from "./modules/pickers";
import loading from "./modules/loading";
import projects from "./modules/projects";
import versions from "./modules/versions";
import alerts from "./modules/alerts";
import publish from "./modules/publish";
import templates from "./modules/templates";

import { createStore } from "vuex";

export default createStore({
  modules: {
    auth,
    components,
    view,
    pickers,
    loading,
    projects,
    versions,
    alerts,
    publish,
    templates,
  },
});
